.section-seja-associado {
    background: $azul url("../img/seja-associado-bg.png") no-repeat scroll center center;
    max-height: 270px;
    height: 270px;
    padding: 95px 0;

    h2,
    a {
        color: #fff;
        text-transform: uppercase;
        text-align: center;
    }
    h2 {
        margin-bottom: 20px;
    }
    a {
        border: 1px solid #fff;
        padding: 20px 30px;
        max-width: 100%;
    }
}

.newsletter {
    background: $branco;
    color: $cinza;
    text-align: right;
    box-shadow: 0px 0px 9px -5px #000;
    p {
        font-weight: 600;
        text-align: left;
        padding-top: 10px;
        font-size: 16px;
        float: left;
        text-transform: uppercase;
    }
    .default-form__submit {
        background-color: $cinza;
        border: 0;
        height: 40px;
        width: 5%;
        margin-top: 1px;
        margin-left: -5px;
        color: $branco;
        font-size: 14px;
        font-weight: 700;
        line-height: 36px;
        text-align: center;
        text-transform: uppercase;
        border-radius: 0px 15px 15px 0px;
    }
    .form__input {
        height: 40px;
        width: 40%;
        padding: 0 10px;
        border: 1px solid $cinza;
        border-radius: 15px 0px 0px 15px;
        outline: none;
        font-size: 14px;
        color: #666;
    }
    &:focus {
        border: 1px solid #fe9d48;
    }
}
.captura-de-lead {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #1d2b7d;
    background-image: url(../../assets/img/bg-leads.jpg);
    background-position: center center;
    background-size: cover;
}

.titulo-lead {
    color: $branco;
    display: block;
    margin-bottom: 50px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    font: {
        size: 30px;
        weight: 300;
    }
}

.click-captura {
    display: block;
    width: 100%;
    padding-top: 30px;
    height: 220px;
    text-align: center;
    @include transition(0.3s);

    .icone-captura {
        display: block;
        margin: 0 auto;
        width: 60px;
        height: 60px;
        line-height: 70px;
        background: $branco;
        @include radius(50%);
        @include transition(0.3s);
        margin-bottom: 25px;
        .icon-whatsapp {
            display: inline-block;
            height: 28px;
            width: 28px;
            background: url("../img/icones/icone_whatsapp_diferenciais.png");
            position: relative;
        }
        i {
            color: $vermelho;
            @include transition(0.3s);
            font: {
                size: 30px;
            }
        }
    }

    .nome-captura {
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $branco;
        margin-bottom: 30px;
        display: inline-block;
        border: solid 3px white;
        border-radius: 0px 30px 30px 30px;
        padding: 20px;
        font: {
            size: 16px;
            weight: 800;
        }
    }

    .detalhe {
        display: none;
        width: 50%;
        height: 5px;
        background: $branco;
        margin: 0 auto;
        @include transition(0.3s);
        opacity: 0;
    }

    &:hover {
        .nome-captura {
            background: $laranja;
            border-color: $laranja;
            color: $preto;
        }
        .icone-captura {
            i {
                color: $laranja;
            }
        }
        .detalhe {
            opacity: 1;
        }
    }
}

.localizacao {
    position: relative;
    background: $secondary-color;
    color: $branco;
}

.caixa-de-informacao {
    padding-top: 40px;
    padding-bottom: 40px;
}

.titulo-localizacao {
    margin-bottom: 35px;
    color: $branco;
    display: inline-block;
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font: {
        size: 24px;
        weight: 300;
    }
}
#map {
    width: 100%;
    height: 250px;

    iframe {
        width: 100%;
        height: 100%;
    }
}
footer {
    padding-top: 22px;
    padding-bottom: 22px;
}
.social-media-footer {
    li {
        display: inline;
        margin-left: 10px;
        a {
            i {
                font-size: 50px;
            }
        }
    }
}
.menu-footer {
    li {
        margin-bottom: 12px;

        a {
            font-family: "Roboto Condensed", sans-serif;
            font-size: 0.8rem;

            img {
                width: 100px;
                &:hover {
                    opacity: 0.5;
                }
            }
        }
    }
}

.logos-footer {
    a {
        img {
            width: 100px;
            &:hover {
                opacity: 0.5;
            }
        }
    }
}

.title-menu-footer {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 1.1rem;
    font-weight: 500;
    text-transform: uppercase;
}

.redes-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;

    li {
        padding: 0px 5px;
        a {
            font-size: 1.5rem;
            display: flex;
            padding: 5px;
            border-radius: 5px;
            color: #fff;

            &:hover {
                opacity: 0.8;
            }
        }
    }

    .link-instagram {
        background: #c13584;
    }

    .link-facebook {
        background: #3b5998;
    }

    .link-whatsapp {
        background: #25d366;
    }

    .link-telefone {
        background: #fe9d48;
    }
}

.btn-colaborador-footer {
    background-color: $main-color;
    color: white;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 0.8rem;
    font-weight: 300;
    width: 200px;

    &:hover {
        opacity: 0.7;
        color: #fff;
    }
}

.btn-contato-footer {
    background-color: #25d366;
    color: #fff;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 0.8rem;
    font-weight: 300;
    width: 200px;

    &:hover {
        opacity: 0.7;
        color: #fff;
    }
}

.button-footer {
    display: block;
    padding: 6px 20px;
    border: 1px solid white;
    margin-bottom: 12px;
    text-align: center;
}

.grupo-brandao {
    padding-bottom: 50px;

    h2 {
        color: $preto;
    }
    a:hover {
        img {
            filter: grayscale(0);
        }
    }
    img {
        margin-bottom: 10px;
        padding: 5px 10px;
        //border: solid 1px $cinza;
        border-radius: 15px;
        width: 150px;
        filter: grayscale(1);
    }
    p {
        color: $preto;
        font-weight: 600;
    }
}
.grupo-brandao-mobile {
    padding-bottom: 50px;

    h2 {
        color: $preto;
    }

    img {
        margin: 0 auto;
    }
}

.contato-home {
    background-color: none;
    border-color: black;
}
.float-menu {
    position: fixed;
    bottom: 15px;
    right: 15px;
    background-color: $main-color;
    padding: 10px 15px;
    border-radius: 50px;

    .clique-menu {
        margin-top: 0;
        color: white;
    }
}
/*************************************************/
/*************************************************/

/* Mobile */

@media screen and (min-width: 320px) {
    #map {
        position: inherit;
        left: inherit;
        width: 100%;
    }
    .ms {
        text-align: center;
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .newsletter {
        .form__input {
            width: 85%;
        }
        .default-form__submit {
            width: 15%;
        }
    }

    .caixa-de-informacao {
        text-align: center;
    }
}

@media screen and (min-width: 992px) {
}

@media screen and (min-width: 1200px) {
    #map {
        height: 250px;
        width: 100%;
    }
    .ms {
        text-align: right;
    }
}

.box-assinatura {
    background: $branco;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: {
        top: 13px;
        bottom: 13px;
    }

    .todos-os-direitos {
        text-align: left;

        .copy {
            color: #363636;
            display: inline-block;
            padding-left: 10px;
            font: {
                weight: 300;
                size: 11px;
                style: italic;
            }
        }
    }

    .ass {
        // text-align: right;
    }
}
