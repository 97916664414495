.form-contato {
    input {
        @include radius(0);
        margin-bottom: 20px;
        display: inline-block;
        height: 40px;
        border: none;
        border-bottom: solid 1px black;
    }

    textarea {
        @include radius(0);
        margin-bottom: 20px;
        display: inline-block;

        border: solid 1px black;
    }

    select {
        margin-bottom: 20px;
        display: inline-block;
        border: none;
        border-bottom: solid 1px black;
    }
}

.informacoes-da-pagina-de-contato {
    text-align: center;
    margin-bottom: 100px;
    .email-contato,
    .numero-contato,
    .numero-whatsapp {
        margin-right: 30px;
        color: $azul;
        font-size: 14px;
        font-weight: 300;
        strong {
            font-weight: 800;
        }
    }
}

.box-contato {
    border: solid 1px $main-color;
    padding: 20px;
}

.bg-contato {
    background-image: url("../img/fale-conosco.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    padding: 50px 0;
    margin-bottom: 60px;

    .titulo-pagina {
        color: white;
        text-align: left;
    }
}

.bg-colaboradores {
    background-image: url("../img/colaboradores.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    padding: 50px 0;
    margin-bottom: 60px;

    .titulo-pagina {
        color: white;
        text-align: left;
    }
}

.bg-trabalhe {
    background-image: url("../img/trabalhe-conosco.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    padding: 50px 0;
    margin-bottom: 60px;

    .titulo-pagina {
        color: white;
        text-align: left;
    }
}

/*************************************************/
/*************************************************/

/* Mobile */

@media screen and (min-width: 320px) {
    .informacoes-da-pagina-de-contato {
        text-align: left;
        span {
            display: inline-block;
            margin-right: 0;
            width: 100%;
            margin-bottom: 20px;
        }
    }
}

@media screen and (min-width: 540px) {
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 992px) {
    .informacoes-da-pagina-de-contato {
        text-align: left;
        span {
            display: inline-block;
            margin-right: 0;
            width: inherit;
            margin-bottom: 20px;
        }
    }
}

@media screen and (min-width: 1200px) {
}

@media screen and (min-width: 1400px) {
}

.simple-text {
    color: #444;
    font-family: "Nunito Sans", sans-serif;
    font-size: 1.2rem;
    font-weight: 300;

    p {
        text-align: justify;
    }
}

.titulo-page {
    font-family: "Roboto Condensed", sans-serif;
    color: $main-color;
}

.btn-trabalhe {
    box-shadow: 0px 0px 9px -5px #000;
    background-color: $main-color;
    color: #fff;
    text-transform: uppercase;
    font-family: "Roboto Condensed", sans-serif;

    &:hover {
        background-color: darken($main-color, 10%);
        color: #fff;
        box-shadow: none;
    }
}
