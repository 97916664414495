header {
	background-color: $main-color;
	background-position: top center;
	background-size: cover;
	padding: {
		top: 15px;
		bottom: 15px;
	}
	position: fixed;
	width: 100%;
	z-index: 11;
	top: 0;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}
.logo {
	display: inline-block;
	a {
		display: block;
		width: 100%;
		img {
			width: 100%;
		}
	}
}

.btn-default-submit {
	height: 40px;
	color: $branco;
	background: $azul;
	@include transition(0.3s);
	display: inline-block;
	margin-bottom: 15px;
	cursor: pointer;
	width: 100%;
	border: none;
	font-size: 18px;
	@include radius(50px);
	font-weight: 700;
	text-transform: uppercase;
	&:hover {
		background: $azulescuro;
	}
}

.informacacoes-gerais-header {
	border-bottom: solid 1px #59a0b7;
	padding-bottom: 5px;
	.ligamos-para-voce {
		margin-right: 15px;
		display: inline-block;
		letter-spacing: 0px;
		background: $verde;
		color: $branco;
		text-align: center;
		height: 30px;
		line-height: 30px;
		padding: {
			left: 15px;
			right: 15px;
		}
		text-transform: uppercase;
		@include radius(10px);
		@include transition(0.3s);
		font: {
			weight: 700;
			size: 13px;
		}
		&:hover {
			background: $verde-escuro;
			color: $branco;
		}
	}
	.ms {
		display: inline-block;
		li {
			display: inline-block;
			margin: {
				right: 2px;
				left: 2px;
			}
			a {
				display: block;
				width: 100%;
				i {
					@include transition(0.3s);
					color: $menu-text;
					font-size: 24px;
					&:hover {
						color: $branco;
					}
				}
			}
		}
	}
}

.menu {
	width: 100%;
	text-align: right;
	margin-top: 20px;
	li {
		display: inline-block;
		margin-left: 25px;
		margin-right: 20px;
		a {
			display: inline-block;
			text-align: center;
			text-transform: uppercase;
			color: $menu-text;
			letter-spacing: 0px;
			opacity: 0;
			font: {
				weight: 300;
				size: 1rem;
				family: "Roboto Condensed", sans-serif;
			}
		}
		&:last-child {
			margin-right: 0;
		}
	}
}

.menu li a.fade-in {	
	animation: fadeInDrop 2s ease-in-out;
	opacity: 1;
}

/*************************************************/
/*************************************************/
/* Mobile */
@media screen and (max-width: 480px) {
	.logo {
		max-width: 135px;

		img {
			width: 75% !important;
		}
	}
}
@media screen and (min-width: 320px) {
	.informacacoes-gerais-header {
		text-align: right;
		.ligue-agora,
		.whatsapp {
			display: block;
			margin-top: 30px;
			width: 100%;
			span {
				display: none;
			}
		}
	}

	.clique-menu {
		margin-top: 20px;
		display: flex;
		font-size: 30px;
		color: $branco;
		&:active {
			color: $branco;
		}
		&:focus {
			color: $branco;
		}
	}

	.menu {
		text-align: center;
		display: none;
		li {
			margin-left: 0;
			display: block;
			width: 100%;
			margin-bottom: 15px;
			text-align: center;
		}
	}
}

@media screen and (min-width: 540px) {
	.informacacoes-gerais-header {
		.ligue-agora,
		.whatsapp {
			margin-top: 50px;
		}
	}
	.clique-menu {
		margin-top: 35px;
		font-size: 40px;
	}
}

@media screen and (min-width: 1200px) {
	.menu {
		text-align: right;
		display: block;
		margin-top: 30px;
		li {
			margin-left: 15px;
			margin-right: 0px;
			display: inline-block;
			width: auto;
			margin-bottom: 15px;
			text-align: center;
		}
	}
	.informacacoes-gerais-header {
		.ligue-agora,
		.whatsapp {
			display: inline-block;
			margin-top: 0;
			width: auto;
			span {
				display: inline;
			}
			margin-right: 12px;
		}
		.ms {
			display: inline-block;
			width: inherit;
		}
	}
}
