h2.section-heading {
    font-family: "Roboto Condensed", sans-serif;
    color: $main-color !important;
    font-size: 1.3rem;
    font-weight: 700;
    margin-bottom: 30px;
    text-align: center;
    text-transform: uppercase;
}

.news-card {
    figure {
        background-color: #ddd;
        position: relative;
        height: 0;
        padding-bottom: 50%;
        overflow: hidden;
        display: block;
        margin-bottom: 10px;
        img {
            width: 100%;
            display: block;
            position: absolute;
            z-index: 1;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    h1,
    p {
        color: #999;
        text-align: justify;
    }
    h1 {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    p {
        font-size: 12px;
        margin: 0;
        &.resume {
            margin-bottom: 15px;
        }
    }
    a.news-read {
        border: 1px solid #999;
        background-color: #fff;
        margin: 0 auto;
        padding: 5px 20px;
    }
}

.read-more {
    max-width: 200px;
}

.custom-btn {
    color: #999;
    border: 1px solid #999;
    background-color: #fff;
    margin: 0 auto;
    padding: 5px 20px;
}

.box-news {
    padding-top: 70px;
    padding-bottom: 100px;

    input {
        height: 50px;
        @include radius(50px);
        width: 100%;
        border: solid 1px #cccccc;
        padding-left: 20px;
        padding-right: 20px;
    }

    .enviar-contato {
        height: 50px;
        line-height: 50px;
        color: $branco;
        background: $laranja;
        @include transition(0.3s);
        display: inline-block;
        margin-bottom: 0px;
        margin-top: 0;
        cursor: pointer;
        width: 100%;
        border: none;
        font-size: 18px;
        @include radius(50px);
        font-weight: 800;
        text-transform: uppercase;
        &:hover {
            background: $vermelho;
        }
    }
}

.icone-news {
    display: inline-block;
    vertical-align: top;
    i {
        color: $vermelho;
        font-size: 100px;
    }
}

.texto-news {
    margin-bottom: 65px;
}

.sobre-news {
    display: inline-block;
    width: 45%;
    text-align: left;
    margin-left: 20px;

    .titulo-pagina {
        width: 100%;
        margin-bottom: 20px;
        margin-top: 0;
    }

    .pequeno-texto-news {
        color: #615f6c;
        font-weight: 400;
        font-size: 14px;
        display: inline-block;
        width: 60%;
    }
}

.slider-associados {
    .item-slider {
        margin: 0 5px;
    }
    .img-padding {
        height: 0;
        padding-bottom: 50%;
        position: relative;
        overflow: hidden;

        img {
            position: absolute;
            max-width: 100%;
            display: block;
            z-index: 1;
            top: 0;
            bottom: 0;
            margin: auto;
            max-height: 140px;
        }
    }
}

.content {
    padding-bottom: 40px;
}

/*************************************************/
/*************************************************/

/* Mobile */

@media screen and (min-width: 320px) {
    .sobre-news {
        display: block;
        text-align: center;
        width: 100%;
        margin-left: 0;
        .pequeno-texto-news {
            width: 100%;
        }
    }
    .box-news {
        input {
            margin-bottom: 20px;
        }
    }
}

@media screen and (min-width: 540px) {
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 992px) {
}

@media screen and (min-width: 1200px) {
    .sobre-news {
        display: inline-block;
        width: 45%;
        text-align: left;
        margin-left: 20px;

        .titulo-pagina {
            width: 100%;
            margin-bottom: 20px;
            margin-top: 0;
        }
    }
    .box-news {
        input {
            margin-bottom: 0;
        }
    }
}

@media screen and (min-width: 1400px) {
}

.bloco-grupo {
    background-color: #f9f9f8;
}

.bloco-cards {
    background-color: #f9f9f8;
}

.container-image-grupo {
    width: 150px;
    height: 100px;
    text-align: center;
    margin: 0 auto;
    border: solid 1px #e5e5e5;
    background-color: #fff;
    border-radius: 5px;
}

.container-grupo {
    opacity: 0;
}

.container-grupo.fade-in {
    opacity: 1;
    animation: fadeIn 2s forwards;
}

.bloco-historia {
    .texto-historia-home {
        color: #444;
        font-family: "Nunito Sans", sans-serif;
        font-size: 1.2rem;
        font-weight: 300;
    }
}

.slider-grupo {
    .item-slider {
        background: #fff;
        /* width: 200px !important; */
        height: 300px;
        display: flex !important;
        align-items: center;
        border: solid 1px #e5e5e5;
    }
}

.container-card-home {
    opacity: 0;
}

.container-card-home.fade-in {
    opacity: 1;
    animation: fadeInDrop 2s forwards;
}

//Cards
.card-home {
    width: 100%;
    box-shadow: 0px 0px 9px -5px #000;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    text-align: center;

    &:hover {
        box-shadow: none;
    }

    i {
        font-size: 4rem;
        margin-bottom: 15px;
    }
}

.card-home-white {
    background-color: #fff;
    h3 {
        font-family: "Roboto Condensed", sans-serif;
        color: #2289aa;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1.5rem;
        text-align: center;
        margin-bottom: 7px;
    }
    p {
        color: #444;
        font-family: "Nunito Sans", sans-serif;
        font-size: 1.1rem;
        font-weight: 300;
    }

    .btn-card-home {
        box-shadow: 0px 0px 9px -5px #000;
        background-color: $main-color;
        color: #fff;
        text-transform: uppercase;
        font-family: "Roboto Condensed", sans-serif;

        &:hover {
            background-color: darken($main-color, 10%);
            color: #fff;
            box-shadow: none;
        }
    }

    i {
        color: $main-color;
    }
}

.card-home-color {
    background-color: $main-color;

    h3 {
        font-family: "Roboto Condensed", sans-serif;
        color: #fff;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1.5rem;
        text-align: center;
        margin-bottom: 7px;
    }
    p {
        color: #fff;
        font-family: "Nunito Sans", sans-serif;
        font-size: 1.1rem;
        font-weight: 300;
    }

    .btn-card-home {
        box-shadow: 0px 0px 9px -5px #000;
        background-color: #fff;
        color: $main-color;
        text-transform: uppercase;
        font-family: "Roboto Condensed", sans-serif;

        &:hover {
            background-color: darken(#fff, 10%);
            box-shadow: none;
        }
    }

    i {
        color: #fff;
    }
}
