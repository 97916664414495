.form__input
    height: 40px
    width: 50%
    padding: 0 10px
    border: 1px solid #bfbfbf
    border-radius: 3px 0px 0px 3px
    outline: none
    font-size: 14px
    color: #666

    &:focus
        border: 1px solid #fe9d48


.about__footer-first
    color: white
    padding-top: 15px

    p
        display: inline-block
        margin-right: 25px
        font-size: 16px
        text-transform: uppercase

    ul
        display: inline-block

        li
            display: inline-block
            margin-right: 10px
            font-size: 30px
    h3
        display: inline-block
        margin-left: 15px
#section-institucional
    .container
        border-bottom: solid 3px #fff

    .newsletter-box
        color: #fff
        text-align: left
        padding-top: 15px
        padding-bottom: 15px

        ul
            display: inline-block
        img
            width: 50px

        .col-sm-6
            padding-left: 5px
            &:first-child
                padding-right: 5px

        p

            text-align: left
            display: inline-block
            font-size: 16px
            float: left
            padding-top: 15px
            margin-right: 15px
            text-transform: uppercase



@media (max-width: em(900px))
    .about_footer

        background-color: #1B1B1B
        height: auto

    .about__footer-first


        h3
            float: none
            width: 100%
            text-align: center
            margin-left: 0

    .newsletter-box


        p

            margin-bottom: 20px
            text-align: center
            float: none



/*------------------------------------*
    #social-list
/*------------------------------------*/

.social-list
    .social-list__item
        display: inline-block
        margin-left: 10px

        &:first-child
            margin-left: 0

    .social-list__link
        display: block
        height: 25px
        width: 25px
        text-indent: -9999px
        background-image: url(../img/sprite_social_icons.png)
        background-repeat: no-repeat

        &--facebook
            background-position: 0 0

        &--twitter
            background-position: -45px 0

        &--youtube
            background-position: -90px 0

        &--instagram
            background-position: -135px -1px

    //locations styles

    .site-header &
        float: left
        margin-top: 7px

    .about__footer &
        float: left
        margin-top: 17px
