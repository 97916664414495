.slider{
    height: auto;
    max-width:1920px;
    visibility: hidden;
	.item-slider{
		height: auto;
		text-align: center;
		img{
			width: 100%;
			display: inline-block;
		}
	}
}

.slider-mobile{
    display: none;
}

@media (max-width: 700px){
	.slider-desktop{
		display: none;
	}
	.slider-mobile{
		display: block;
	}
}

.slick-prev{
  z-index: 10;
  left:25px;
}

.slick-prev:before{
  font-size:40px;
  font-family: monospace;
  font-weight: bold;
  z-index: 10;
  left:25px;
  color:white;
  background-color: black;
  padding: 0px 10px 6px 10px;

}

.slick-next{
  right:25px;
}

.slick-next:before{
    font-size:40px;
    font-family: monospace;
    font-weight: bold;
    color:white;
    background-color: black;
    padding: 0px 10px 6px 10px;

}
