body {
	font: {
		font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
		size: 16px;
		weight: 400;
	}
	color: $preto;
	padding-top: 132px;

	@media screen and (max-width: 620px) {
		padding-top: 94px !important;
	}
}

h1,
h2,
h3,
h4,
h5 {
	margin: 0px;
	font-weight: inherit;
}

ul {
	padding: 0;
	margin: 0;
	li {
		list-style: none;
	}
}

a {
	text-decoration: none !important;
	color: inherit;
	&:hover {
		text-decoration: none !important;
		color: inherit;
	}
}

section {
	margin-top: 50px;
}
// Animações

/* Animação de fade-in e queda */
@keyframes fadeInDrop {
	from {
		opacity: 0;
		transform: translateY(-50px);
		/* Ajuste conforme a necessidade */
	}

	to {
		opacity: 1 !important;
		transform: translateY(0);
	}
}

/* Animação fade-in */
@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1 !important;
	}
}
