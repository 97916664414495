.sobre-empresa {
    p {
        color: #444;
        font-family: "Nunito Sans", sans-serif;
        font-size: 1.1rem;
        font-weight: 300;
        text-align: justify;
    }
}

.galeria-de-foto {
    .item-maior-foto {
        .item-foto-maior {
            width: 100%;
            height: auto;
            iframe {
                width: 100%;
            }
        }
    }

    .fotos-menores {
        .item-fotos {
            height: 60px;
            cursor: pointer;
            margin-left: -5px;
            text-align: center;
            img {
                display: inline-block;
                max-width: 100%;
                height: 100%;
            }
        }
    }
}

.institucional {
    background-color: #f2f2f2;
    margin-top: -50px;
    padding: 80px;

    @media screen and (max-width: 480px) {
        padding: 40px !important;
        padding-top: 80px !important;

        h1 {
            margin-bottom: 15px !important;
        }
    }
}
.mapa-onde {
    padding: 80px;

    @media screen and (max-width: 480px) {
        padding: 40px !important;
    }

    p {
        color: #444;
        font-family: "Nunito Sans", sans-serif;
        font-size: 1.1rem;
        font-weight: 300;
        text-align: justify;
    }
}
.atuacao {
    background-color: #f2f2f2;
    padding: 80px;

    @media screen and (max-width: 480px) {
        padding: 40px !important;
    }

    p {
        color: #444;
        font-family: "Nunito Sans", sans-serif;
        font-size: 1.1rem;
        font-weight: 300;
        text-align: justify;
    }
}
/*************************************************/
/*************************************************/

/* Mobile */

@media screen and (min-width: 320px) {
    .galeria-de-foto {
        margin-bottom: 30px;
    }
    .missao,
    .valores {
        height: inherit;
        padding-bottom: 30px;
    }

    .botoes-entre-em-contato {
        margin-top: 20px;
    }
}

@media screen and (min-width: 540px) {
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 992px) {
}

@media screen and (min-width: 1200px) {
    .galeria-de-foto {
        margin-bottom: 0;
    }

    .missao,
    .valores {
        height: 300px;
        padding-bottom: 0;
    }

    .botoes-entre-em-contato {
        margin-top: 0;
    }
}

@media screen and (min-width: 1400px) {
}

.espaco-loja-grande {
    max-width: 400px;
}
.espaco-loja-pequeno {
    max-width: 100px;
}
